import {ROUTE_PRICING} from '@app/navigations/RoutePathConstant';
import {Suspense, lazy} from 'react';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const Pricing = lazy(() =>
  import('../../../screens/Pricing').then(module => ({
    default: module.Pricing,
  })),
);

const PricingNavigation = [
  {
    path: ROUTE_PRICING,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Pricing />
      </Suspense>
    ),
  },
];

export default PricingNavigation;

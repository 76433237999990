import React, {useEffect, useState} from 'react';
import {Menu, Typography} from 'antd';
import * as PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  ROUTE_BASE_ROOT,
  ROUTE_CORRECTION_QUALITY,
  ROUTE_DATENSICHERHEIT,
  ROUTE_FAQ,
  ROUTE_REVIEWS,
} from '@app/navigations/RoutePathConstant';
import {HeaderMenuList} from '@app/screensComponents/HomeScreen/MainHome/Menu/HeaderMenuList/HeaderMenuList';
import {MenuBuilder} from '@app/screensComponents/HomeScreen/MainHome/Menu/MenuBuilder';

const {Text} = Typography;

const HeaderMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [currentPath, setCurrentPath] = useState(
    location.pathname === ROUTE_BASE_ROOT || location.pathname === ''
      ? ROUTE_BASE_ROOT
      : location.pathname.split(ROUTE_BASE_ROOT)[1],
  );

  useEffect(() => {
    if (location) {
      if (currentPath !== location.pathname) {
        setCurrentPath(location.pathname);
      }
    }
  }, [location, currentPath]);

  return (
    <>
      <Menu
        mode={'horizontal'}
        className={'text-base font-semibold w-32 flex border-none'}
        selectable={false}
        items={[
          ...HeaderMenuList.map(menu => MenuBuilder({menu, currentPath})),
          //{
          //  label: (
          //    <Text className={'text-lg font-semibold font-sans '}>
          //      Resourcen
          //    </Text>
          //  ),
          //  children: [
          //    {
          //      label: 'Datensicheirheit',
          //      onClick: () => navigate(ROUTE_DATENSICHERHEIT),
          //    },
          //    {
          //      label: 'Korrekturqualität',
          //      onClick: () => navigate(ROUTE_CORRECTION_QUALITY),
          //    },
          //    {
          //      label: 'FAQs',
          //      onClick: () => navigate(ROUTE_FAQ),
          //    },
          //    {
          //      label: 'Kundenmeinungen',
          //      onClick: () => navigate(ROUTE_REVIEWS),
          //    },
          //  ],
          //},
        ]}
      />
    </>
  );
};

HeaderMenu.propTypes = {
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

export default HeaderMenu;

export const RQ_UPLOAD_DOCUMENT_API = {
  url: 'upload',
  method: 'POST_FORM',
  auth: false,
};
export const RQ_REVIEW_DOCUMENT_API = {
  url: 'review_document',
  method: 'POST_FORM',
  auth: false,
};
export const RQ_USER_DOCUMENT_API = {
  url: 'get_docs_of_user',
  method: 'POST_FORM',
  auth: false,
};

export const RQ_HIDE_DOCUMENT_API = {
  url: 'hide_doc',
  method: 'POST_FORM',
  auth: false,
};
export const RQ_DOCUMENT_THUMBNAIL_API = {
  url: 'api/v1/document/<%= id %>/thumbnail',
  method: 'GET',
  auth: true,
};

export const RQ_DOCUMENT_VIEW_API = {
  url: 'api/v1/document/<%= id %>/view',
  method: 'GET',
  auth: true,
};

export const RQ_DOCUMENT_LOOKUP_API = {
  url: 'api/v1/document/<%= id %>/lookup',
  method: 'GET',
  auth: true,
};

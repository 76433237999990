import {Button, Col, Popover, Typography} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ROUTE_ACCOUNT} from '@app/navigations/RoutePathConstant';
import {LogoutButton} from '@app/screensComponents/HomeScreen/MainHome/HeaderLayout/LogoutButton';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {hideLectoriaAssistent} from '@app/redux/actions';
import dayjs from 'dayjs';

const {Text} = Typography;

const UserProfile = ({auth, handleHideLectoriaAssistentBackground}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const ShowPopOver = () => {
    setOpen(true);
  };
  const handleOpenChange = newOpen => {
    setOpen(newOpen);
  };

  const userInfo = auth?.user;
  const initials =
    userInfo?.first_name && userInfo?.last_name
      ? `${userInfo.first_name.charAt(0).toUpperCase()}${userInfo.last_name.charAt(0).toUpperCase()}`
      : '';

  const credits = userInfo.credit;

  return (
    <Col className={'pt-1'}>
      <Button type={'primary'} onClick={ShowPopOver} shape={'circle'}>
        {initials}
      </Button>
      <Popover
        arrow={false}
        overlayInnerStyle={{
          border: '1px solid rgb(0, 69, 229, 0.5)',
          marginTop: '0.5rem',
        }}
        content={(
          <Col className={'flex-col w-80 p-1'}>
            <Text
              className={
                'p-2 h-12 w-full text-start text-secondary-500 font-semibold border-none rounded-none block'
              }
              type={'default'}
            >
              {userInfo?.email}
            </Text>

            <div
              className={
                'bg-gray-50 border border-solid border-gray-50 flex justify-between items-center p-2 rounded-sm'
              }
            >
              <Text className={'text-secondary font-semibold text-sm'}>
                LECTORIA Korrigieren
              </Text>
              <Text className={'text-primary font-semibold text-sm'}>PRO</Text>
            </div>

            <div className={'flex items-center justify-between py-4'}>
              <div className={'flex flex-col'}>
                <Text className={'text-sm font-sans font-semibold'}>
                  Verbleibende Kredite
                </Text>{' '}
                <Text className={'text-lg font-sans font-bold text-secondary'}>
                  {credits}
                </Text>
              </div>
              <div className={'flex flex-col gap-2'}>
                <Button
                  className={
                    'px-4 w-fit text-sm border border-2 font-semibold rounded-md'
                  }
                  type={'primary'}
                  ghost
                  href={ROUTE_ACCOUNT}
                >
                  Jetzt nachfüllen
                </Button>
              </div>
            </div>

            <Button
              onClick={() => {
                handleHideLectoriaAssistentBackground();
                navigate(ROUTE_ACCOUNT);
              }}
              className={
                'h-14 py-4 px-0 text-start text-secondary-500  font-semibold w-full border-b border-t border-l-0 border-r-0 border-solid rounded-none'
              }
              style={{borderColor: '#B1B2B4'}}
              type={'default'}
            >
              Konto
            </Button>
            <LogoutButton />
          </Col>
        )}
        trigger={'click'}
        open={open}
        placement={'bottomLeft'}
        onOpenChange={handleOpenChange}
      ></Popover>
    </Col>
  );
};

UserProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  handleHideLectoriaAssistentBackground: PropTypes.func,
};
const actions = {
  handleHideLectoriaAssistentBackground: hideLectoriaAssistent,
};
const mapStateToProps = state => {
  const auth = selectAuth(state);
  return {auth};
};
export default connect(mapStateToProps, actions)(UserProfile);

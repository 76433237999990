import {ROUTE_CORRECTION_QUALITY} from '@app/navigations/RoutePathConstant';
import {Suspense, lazy} from 'react';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const CorrectionQuality = lazy(() =>
  import('../../../screens/CorrectionQuality').then(module => ({
    default: module.CorrectionQuality,
  })),
);

const CorrectionQualityNavigation = [
  {
    path: ROUTE_CORRECTION_QUALITY,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CorrectionQuality />
      </Suspense>
    ),
  },
];

export default CorrectionQualityNavigation;

import {rqCreateMultipartRequest, rqCreateRequest} from './rqHttpsUtils';
import {
  RQ_USER_ADD_CREDITS_API,
  RQ_USER_BUY_CREDITS_API,
  RQ_USER_ENTER_WAITLIST_API,
  RQ_USER_FORGOT_PASSWORD_API,
  RQ_USER_LOGIN_API,
  RQ_USER_SIGNUP_API,
} from './constants';

export const RQUserLoginAPI = ({email, password, signal}) => {
  return rqCreateRequest(
    RQ_USER_LOGIN_API,
    {
      email: email,
      password: password,
    },
    null,
    null,
    signal,
  );
};

export const RQUserSignupAPI = ({
  first_name,
  last_name,
  email,
  password,
  password_confirm,
  signal,
}) => {
  return rqCreateRequest(
    RQ_USER_SIGNUP_API,
    {
      email: email,
      first_name,
      last_name,
      password: password,
      password_confirm,
    },
    null,
    null,
    signal,
  );
};
export const RQUserForgotPasswordAPI = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_USER_FORGOT_PASSWORD_API,
    {...formData},
    null,
    {email: email},
    signal,
  );
};

export const RQUserWaitListAPI = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_USER_ENTER_WAITLIST_API,
    {...formData},
    null,
    {email: email},
    signal,
  );
};

export const RQUserBuyCreditsApi = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_USER_BUY_CREDITS_API,
    {...formData},
    null,
    {email: email},
    signal,
  );
};

export const RQUserAddCreditsAPI = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_USER_ADD_CREDITS_API,
    {...formData},
    null,
    {email: email},
    signal,
  );
};

import React from 'react';
import {Button, Col, Layout, Space, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {
  ROUTE_ACCOUNT,
  ROUTE_LECTORIA,
} from '@app/navigations/RoutePathConstant';
import {FeedbackModal} from '@app/screensComponents/HomeScreen/MainHome/FeedbackModal';
import {HeaderMenu} from '@app/screensComponents/HomeScreen/MainHome/Menu/HeaderMenu';
import {UserProfile} from '@app/screensComponents/HomeScreen/MainHome/HeaderLayout/UserProfile';

const {Header} = Layout;
const {Text} = Typography;

const HeaderLayout = () => {
  const navigate = useNavigate();
  return (
    <Header
      className={
        'bg-white h-20 z-20 top-0 w-auto flex justify-center sticky border-2'
      }
    >
      <div
        className={
          'max-w-[1208px] w-full flex justify-between box-content items-center px-4'
        }
      >
        <Col className={'flex justify-center items-center'}>
          <Text
            className={
              'text-3xl cursor-pointer text-secondary font-bold font-sans tracking-wide'
            }
            onClick={() => navigate(ROUTE_LECTORIA)}
          >
            LECTORIA
          </Text>
        </Col>
        <FeedbackModal />
        <Space className={'flex justify-end'}>
          <Button
            type={'primary'}
            className={
              'h-10 text-lg font-bold flex items-center justify-center color-white px-6'
            }
            style={{borderRadius: '5px'}}
            size={'large'}
            href={ROUTE_ACCOUNT}
          >
            Jetzt Kredite kaufen
          </Button>
          <HeaderMenu />
          <UserProfile />
        </Space>
      </div>
    </Header>
  );
};

export default HeaderLayout;

import React from 'react';
import {NavLink} from 'react-router-dom';
import {Typography} from 'antd';

const {Text} = Typography;

const MenuBuilder = ({menu, currentPath}) => {
  const {key, path, label} = menu;

  return {
    key: key,
    title: label,
    label: (
      <NavLink to={`${path}`}>
        <Text
          className={`text-lg font-semibold font-sans ${'/' + key === currentPath ? 'text-primary' : 'text-secondary'}`}
        >
          {label}
        </Text>
      </NavLink>
    ),
  };
};

export default MenuBuilder;

import {
  ROUTE_LECTORIA,
  ROUTE_PRICING,
} from '@app/navigations/RoutePathConstant';

export const HeaderMenuList = [
  {
    key: ROUTE_LECTORIA,
    path: ROUTE_LECTORIA,
    label: 'LECTORIA',
  },
  //{
  //  key: ROUTE_PRICING,
  //  path: ROUTE_PRICING,
  //  label: 'Preise',
  //},
];

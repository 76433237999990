import {Suspense, lazy} from 'react';
import {ROUTE_REVIEWS} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const Reviews = lazy(() =>
  import('../../../screens/Reviews').then(module => ({
    default: module.Reviews,
  })),
);

const ReviewsNavigation = [
  {
    path: ROUTE_REVIEWS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Reviews />
      </Suspense>
    ),
  },
];

export default ReviewsNavigation;

export const RQ_GET_USER_INFO_API = {
  url: 'get_user_info',
  method: 'POST_FORM',
  auth: false,
};

export const RQ_DELETE_USER_API = {
  url: 'delete_account',
  method: 'POST_FORM',
  auth: false,
};

export const RQ_UPDATE_USER_INFO_API = {
  url: 'update_user_info',
  method: 'POST_FORM',
  auth: false,
};

export const RQ_CHANGE_PASSWORD_API = {
  url: 'change_password',
  method: 'POST_FORM',
  auth: false,
};

export const RQ_USER_ADD_CREDITS_API = {
  url: 'add_credit',
  method: 'POST_FORM',
  auth: false,
};

export const RQ_USER_BUY_CREDITS_API = {
  url: 'buy_credit',
  method: 'POST_FORM',
  auth: false,
};
